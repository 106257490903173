import React, { Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import Pages
const Home = lazy(() => import("./features/Home"));
const Art = lazy(() => import("./features/Art"));
const Culture = lazy(() => import("./features/Culture"));
const Learn = lazy(() => import("./features/Learn"));
const Faq = lazy(() => import("./features/Faq"));
const Lang = lazy(() => import("./features/Lang"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<>Loading...</>}>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/story" element={<Art />} />
            <Route path="/culture" element={<Culture />} />
            <Route path="/learn" element={<Learn />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/" element={<Lang />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
